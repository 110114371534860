<template>
  <div>
    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="agent_bill_id"
    >
      <div slot="no" slot-scope="no, record">
        <router-link
          :to="{ name: 'agency_monthly_bill_subscribe_order_info',
                 params: { subscribe_order_id: record.agent_subscribe_order_id },
                 query: { bill_id: billId }}"
        >
          {{ no }}
        </router-link>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findSubscribeCardBills } from '@/api/agent_monthly_bill'
import { formatBigNumber, formatCurrency } from '@/utils/filter'

export default {
  name: 'AgentSubscribeBillList',
  components: {
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  props: {
    billId: {
      type: Number,
      required: true
    }
  },
  computed: {
    agentType() {
      return this.$store.getters.userAgentType
    },

    columns() {
      var columnArr = [
        {
          title: '账单批次',
          dataIndex: 'no',
          width: 150,
          scopedSlots: { customRender: 'no' }
        },
        {
          title: '账单时间',
          width: 200,
          dataIndex: 'created_at'
        },
        {
          title: '卡号数量',
          dataIndex: 'cards_count',
          width: 100,
          customRender: formatBigNumber
        },
        {
          title: '订购周期数',
          dataIndex: 'cycles',
          width: 100,
          customRender: formatBigNumber
        },
        {
          title: '账单金额(元)',
          dataIndex: 'total_fee',
          width: 100,
          customRender: formatCurrency
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 100,
          ellipsis: true
        }
      ]

      return columnArr
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findSubscribeCardBills(this.billId, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }

        this.loading = false
      })
    }
  }
}
</script>

